




































import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class NoPlace extends Vue {
  @Prop({}) data!: any;
}
